<template>
  <secondary-page-layout :title="$t('chat')">
    <div class="chat">
      <div class="chat-area">
        <template v-for="message in messages">
          <div class="message" :class="[isMyMessage(message.participation) ? 'my-message' : 'other-message']">
            <div class="message-content injected-html" v-html="message.body"></div>
            <span class="time">16:15</span>
          </div>
        </template>
      </div>
      <div class="chat-bar form-group">
        <div @click="uploadFile" class="d-flex align-items-center">
          <input type="file" class="input-file" ref="fileInput">
          <icon icon-name="clip-icon"></icon>
        </div>
        <div @click="isFAQShown=true" class="d-flex align-items-center">
          <icon icon-name="book-icon"></icon>
        </div>
        <textarea class="textarea" :placeholder="$t('askUs')" v-model="form.message"></textarea>
        <div class="d-flex align-items-center" @click="sendMessage">
          <icon icon-name="send-message-icon"></icon>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <faq-alter-modal :isFAQShown="isFAQShown" @closed="closeAlterModal" v-if="isFAQShown"/>
    </transition>
  </secondary-page-layout>
</template>

<script>
import FaqAlterModal from "../../components/Modals/FaqAlterModal";
import {mapGetters} from "vuex";

export default {
  name: "Chat",
  components: {
    FaqAlterModal
  },
  data() {
    return {
      isFAQShown: false,
      username: null,
      conversation: null,
      form: {
        message: ''
      },
      messages: [],
    }
  },
  computed: {
    ...mapGetters(['authUser']),
    isMyMessage() {
      return (participation) => {
        return participation.messageable_id === this.authUser.id && participation.messageable_type !== 'App\\Models\\Bot'
      }
    }
  },
  created() {
    const {username} = this.$route.params
    if (username) {
      this.username = username;
      this.startBot();
    }
  },
  methods: {
    async startBot() {
      const { data } = await window.axios.post(`/bots/${this.username}/start`);
      this.conversation = data.conversation;
      this.setListener(this.conversation.id);
    },
    setListener(conversationId) {
      let vm = this
      Pusher.subscribe('private-chat.' + conversationId)
          .bind('message-sent', function({message}) {
            vm.pushMessages(message)
          });
    },
    uploadFile() {
      this.$refs.fileInput.click();
    },
    closeAlterModal() {
      this.isFAQShown = false;
    },
    async getMessages() {
      const {data} = await window.axios.get(`/conversations/${this.conversation.id}/messages`);
      this.messages = data.messages.data;
    },
    async sendMessage() {
      if (this.form.message) {
        const {data} = await window.axios.post(`/bots/${this.conversation.id}/send-message`, this.form)
        this.form.message = ''
      }
    },
    pushMessages(message) {
      let foundMessage = this.messages.find((item) => item.id === message.id);
      if (!foundMessage) this.messages.push(message);
    }
  },
  watch: {
    conversation(value) {
      if (value) {
        this.getMessages();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat {
  padding: 0 16px 0;
  //height: 100%;
  margin-top: auto;

  .chat-area {
    padding-bottom: 30px;
    .message {
      width: 100%;
      display: flex;
      margin-bottom: 16px;
      align-items: flex-end;

      .time {
        font-size: 14px;
        color: #99A3B3;
      }

      .message-content {
        word-wrap: break-word;
        max-width: 75%;
        width: auto;
        padding: 12px 16px;
      }
    }

    .other-message {
      flex-direction: row;

      .time {
        margin-left: 8px;
      }

      .message-content {
        background: #FFFFFF;
        border-radius: 0 16px 16px 16px;
        text-align: left;
      }
    }

    .my-message {
      flex-direction: row-reverse;

      .time {
        margin-right: 8px;
      }

      .message-content {
        color: #fff;
        background: #2AA65C;
        border-radius: 16px 16px 0 16px;
        text-align: right;
      }
    }
  }

  .chat-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 48px;
    background: rgba(247, 247, 247, 0.8);
    box-shadow: inset 0 0.5px 0 rgba(126, 126, 126, 0.2);
    backdrop-filter: blur(40px);
    padding-bottom: 32px;
    padding-top: 10px;

    .input-file {
      display: none;
    }

    .textarea {
      width: 220px;
      height: 36px;
      padding: 8px 12px;
    }
  }
}

.slide-fade-enter-active {
  transition: all 700ms ease;
}

.slide-fade-leave-active {
  transition: all 700ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */
{
  transform: translateY(3000px);
  opacity: 0;
}
</style>
